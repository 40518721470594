import React from "react";
import { CSSObject } from "@emotion/core";
import { EmotionStyles } from "../../interfaces/emotion";
import { fontWeights } from "../../styles/type";
import { variables } from "../../styles/variables";

const styles: EmotionStyles = {
  price: {
    fontWeight: fontWeights.bold,
    marginBottom: variables.spacing.medium
  }
};
const Price = ({
  price,
  cssStyles = []
}: {
  price: number | null;
  cssStyles?: CSSObject[];
}) => {
  if (price === null || price === 0) return null;
  return <p css={[styles.price, ...cssStyles]}>£{price.toFixed(2)}</p>;
};

export default Price;
