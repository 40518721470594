import React from "react";
import { FiExternalLink } from "@react-icons/all-files/fi/FiExternalLink";
import { ProductInterface } from "../../interfaces/product";
import Inner from "../Inner";
import Heading from "../Heading";
import { styles } from "./styles";
import PortableText from "../PortableText";
import ProductCarousel from "./components/ProductCarousel";
import Button from "../Button";
import Price from "../Price";
import BrandLink from "../BrandLink";
import ProductDisclaimer from "./components/ProductDisclaimer";
import ProductFeaturedEpisode from "./components/ProductFeaturedEpisode";
import { trackProductClick } from "../../helpers/trackingEvents";

const Product: React.FC<ProductInterface> = ({
  title,
  _rawBody,
  mainImage,
  images,
  price,
  link,
  brand,
  shopName,
  featuredEpisode
}) => {
  const handleClick = () => {
    trackProductClick(title, brand.title);
  };
  return (
    <>
      <Inner>
        <article css={styles.container}>
          {mainImage && (
            <div css={styles.imagesMobile}>
              <ProductCarousel images={[mainImage, ...images]} />
            </div>
          )}

          <div css={styles.content}>
            <div css={styles.contentInner}>
              {mainImage && (
                <div css={styles.imagesDesktop}>
                  <ProductCarousel images={[mainImage, ...images]} />
                </div>
              )}
              <div css={styles.details}>
                <Heading headingLevel="h1" styles={styles.title}>
                  {title}
                </Heading>
                {brand && (
                  <BrandLink
                    title={brand.title}
                    cssStyles={[!price ? styles.brandLink : {}]}
                  />
                )}
                <Price price={price} />
                <div>
                  {link && (
                    <Button
                      type="secondary"
                      asWrapper={true}
                      cssStyles={styles.button}
                      onClick={handleClick}
                    >
                      <a href={link} target="_blank">
                        Buy {shopName ? `at ${shopName}` : "here"}{" "}
                        <FiExternalLink />
                      </a>
                    </Button>
                  )}
                </div>
                {_rawBody && (
                  <div css={styles.body}>
                    <PortableText blocks={_rawBody} />
                  </div>
                )}
              </div>
            </div>
            <aside css={styles.disclaimer}>
              <ProductDisclaimer />
            </aside>
          </div>
        </article>
      </Inner>
      <ProductFeaturedEpisode featuredEpisode={featuredEpisode} />
    </>
  );
};

export default Product;
