import React from "react";
import { BodyPortableText } from "../../../interfaces/sanity";
import { ChildImageSharp } from "../../../interfaces/images";
import { graphql, useStaticQuery } from "gatsby";
import Heading, { HeadingLevel } from "../../Heading";
import PortableText from "../../PortableText";
import { renderImage } from "../../CTASection/helpers/imageHelpers";
import { EmotionStyles } from "../../../interfaces/emotion";
import { variables } from "../../../styles/variables";
import { colors } from "../../../styles/colors";
import { textStyles } from "../../../styles/type";
import { breakpointMixins } from "../../../styles/breakpoints";
import { getHeadingStyles } from "../../Heading/headingStyles";

interface ProductDisclaimerProps {
  headingLevel?: HeadingLevel;
}

const styles: EmotionStyles = {
  container: {
    display: "flex"
  },
  imageContainer: {
    display: "none",
    [breakpointMixins.lg]: {
      flex: 1,
      minWidth: 240,
      padding: "0 3rem",
      display: "block"
    }
  },
  heading: {
    textTransform: "uppercase",
    ...textStyles.bodyM,
    [breakpointMixins.md]: {
      ...getHeadingStyles("h4", true),
      letterSpacing: 2
    }
  },
  headingIcon: {
    display: "inline-block",
    minWidth: 28,
    marginRight: variables.spacing.small,
    padding: variables.spacing.xSmall,
    [breakpointMixins.lg]: {
      display: "none"
    }
  },
  contentContainer: {
    [breakpointMixins.lg]: {
      padding: `0 ${variables.spacing.large}`
    }
  },
  content: {
    "& p": {
      color: colors.body,
      ...textStyles.bodyXs,
      marginBottom: variables.spacing.small
    }
  }
};

const ProductDisclaimer = ({ headingLevel = "h3" }: ProductDisclaimerProps) => {
  const {
    shopSettings: { _rawProductDisclaimer },
    linksImage
  }: {
    shopSettings: {
      _rawProductDisclaimer: BodyPortableText[];
    };

    linksImage: ChildImageSharp;
  } = useStaticQuery(graphql`
    query {
      shopSettings: sanityShopSettings {
        _rawProductDisclaimer
      }
      linksImage: file(relativePath: { eq: "linksImage.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);
  if (!_rawProductDisclaimer) return null;
  return (
    <div css={styles.container}>
      <div css={styles.imageContainer}>{renderImage(linksImage)}</div>
      <div css={styles.contentContainer}>
        <Heading headingLevel={headingLevel} styles={styles.heading}>
          <div css={styles.headingIcon}>{renderImage(linksImage)}</div>
          NB! Please read the fine print
        </Heading>
        <div css={styles.content}>
          <PortableText blocks={_rawProductDisclaimer} />
        </div>
      </div>
    </div>
  );
};

export default ProductDisclaimer;
