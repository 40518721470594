import { EmotionStyles } from "../../interfaces/emotion";
import { breakpointMixins } from "../../styles/breakpoints";
import { colors } from "../../styles/colors";
import { contentStyles } from "../../styles/content";
import { textStyles } from "../../styles/type";
import { variables } from "../../styles/variables";

const { spacing } = variables;

export const styles: EmotionStyles = {
  container: {
    paddingTop: spacing.medium,

    [breakpointMixins.md]: {
      paddingTop: spacing.large
    }
  },
  imagesDesktop: {
    display: "none",
    width: "50%",
    "& figure": {
      marginTop: 0
    },
    [breakpointMixins.md]: {
      display: "block"
    }
  },
  imagesMobile: {
    marginBottom: spacing.small,
    [breakpointMixins.md]: {
      display: "none"
    }
  },
  title: {
    color: colors.body,
    ...textStyles.headingXs,
    [breakpointMixins.lg]: {
      ...textStyles.headingS
    }
  },
  content: {
    zIndex: 10,
    ...contentStyles
  },
  contentInner: {
    display: "flex",
    flexDirection: "column",
    [breakpointMixins.md]: {
      flexDirection: "row",
      justifyContent: "space-between"
    }
  },
  details: {
    maxWidth: 480,

    [breakpointMixins.md]: {
      padding: `0 ${variables.spacing.medium} 0`
    }
  },
  brandLink: {
    display: "block",
    marginBottom: spacing.small,
    [breakpointMixins.md]: {
      marginBottom: spacing.medium
    }
  },
  body: {
    "& p:last-of-type": {
      marginBottom: spacing.small
    }
  },
  button: {
    width: "auto",
    marginBottom: spacing.large,
    textAlign: "center",
    display: "block",
    "& a": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      textDecoration: "none",
      textTransform: "uppercase",
      lineHeight: 1
    },
    "&:hover a": {
      color: colors.pinkDark
    },
    "& a svg": {
      marginLeft: spacing.small,
      marginBottom: "0.1rem"
    }
  },
  disclaimer: {
    margin: `${spacing.large} 0 ${spacing.xLarge}`,
    [breakpointMixins.md]: {
      padding: `${spacing.small} ${spacing.medium}`
    }
  },
  featuredEpisode: {
    marginTop: spacing.medium,
    marginBottom: spacing.large,
    [breakpointMixins.md]: {
      marginTop: spacing.small
    }
  }
};

export const carouselStyles: EmotionStyles = {
  carousel: {
    "& .swiper-button-next, .swiper-button-prev": {
      transform: "translateY(-100%)",

      "&:after": {
        fontSize: 35
      }
    },
    "& .swiper-pagination-bullets": {
      [breakpointMixins.md]: {
        bottom: variables.spacing.large
      }
    },
    "& .swiper-pagination-bullet-active": {
      background: colors.pinkDark
    }
  }
};
