import { Link } from "gatsby";
import React from "react";
import slugify from "slugify";
import { EmotionStyles } from "../../interfaces/emotion";
import { textStyles } from "../../styles/type";
import { CSSObject } from "@emotion/core";

const styles: EmotionStyles = {
  link: {
    textDecoration: "none",
    ...textStyles.bodyS
  }
};

const BrandLink = ({
  title,
  cssStyles = []
}: {
  title: string;
  cssStyles?: CSSObject[];
}) => {
  return (
    <Link
      to={`/shop/${slugify(title, { lower: true })}`}
      css={[styles.link, ...cssStyles]}
    >
      by {title}
    </Link>
  );
};

export default BrandLink;
