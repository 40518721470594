import React from "react";
import { Pagination, A11y, Keyboard, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { CMSImage } from "../../../interfaces/images";
import Figure from "../../Figure";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import { carouselStyles as styles } from "../styles";

const ProductCarousel: React.FC<{ images: CMSImage[] }> = ({ images }) => {
  if (!images.length) return null;
  return (
    <Swiper
      modules={[Pagination, A11y, Keyboard, Navigation]}
      loop={true}
      pagination={{
        clickable: true
      }}
      keyboard={{
        enabled: true,
        onlyInViewport: true
      }}
      a11y={{
        enabled: true
      }}
      navigation={true}
      css={styles.carousel}
    >
      {images.map((image, index) => {
        return (
          <SwiperSlide key={index}>
            <Figure node={image} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default ProductCarousel;
