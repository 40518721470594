import React from "react";
import { TransistorEpisodeInterface } from "../../../interfaces/podcasts";
import { graphql, useStaticQuery } from "gatsby";
import { BlogPostFeaturedEpisode } from "../../../interfaces/blogPost";
import { getFeaturedEpisode } from "../../../helpers/getFeaturedEpisode";

import { EmotionStyles } from "../../../interfaces/emotion";
import { colors } from "../../../styles/colors";
import { variables } from "../../../styles/variables";
import EpisodePage from "../../EpisodePage";

interface ProductFeaturedEpisodeProps {
  featuredEpisode?: BlogPostFeaturedEpisode;
}

const styles: EmotionStyles = {
  featuredEpisode: {
    background: colors.pinkLight,
    padding: `${variables.spacing.large} 0`
  },
  featuredEpisodeTitle: {
    textTransform: "uppercase",
    textAlign: "center",
    marginBottom: 0
  },
  featuredEpisodeInner: {
    background: colors.white,
    paddingTop: variables.spacing.large,
    paddingBottom: variables.spacing.large
  }
};

const ProductFeaturedEpisode = ({
  featuredEpisode
}: ProductFeaturedEpisodeProps) => {
  const {
    podcasts
  }: {
    podcasts: {
      nodes: TransistorEpisodeInterface[];
    };
  } = useStaticQuery(graphql`
    query {
      podcasts: allTransistorEpisode {
        nodes {
          id
          ...TransistorEpisodeAttributes
        }
      }
    }
  `);
  const featuredEpisodeData = featuredEpisode
    ? getFeaturedEpisode(podcasts.nodes, featuredEpisode)
    : undefined;
  if (!featuredEpisodeData || !featuredEpisode) return null;
  return (
    <EpisodePage
      episode={featuredEpisodeData}
      heading="Learn more in this episode"
      headingStyles={styles.featuredEpisodeTitle}
      headingLevel="h2"
      includeBuyMeACoffee={false}
    />
  );
};

export default ProductFeaturedEpisode;
