import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList, {
  GraphQLError
} from "../components/graphql-error-list";
import SEO from "../components/SEO";
import Product from "../components/Product";
import { toPlainText } from "../helpers/content";
import { SiteInterface } from "../pages";
import { ProductInterface } from "../interfaces/product";

interface ProductTemplateProps {
  data: {
    site: SiteInterface;
    product: ProductInterface;
  };
  errors: Array<GraphQLError>;
}

export const query = graphql`
  query ProductTemplateQuery($id: String!) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    product: sanityProduct(id: { eq: $id }) {
      ...ProductFragment
    }
  }
`;

const ProductPageTemplate: React.FC<ProductTemplateProps> = ({
  data,
  errors
}) => {
  const site = data && data.site;
  const product = data && data.product;
  return (
    <React.Fragment>
      {errors && <SEO title="GraphQL Error" />}
      {product && (
        <SEO
          title={`${product.title || "Untitled"} | ${site.title}`}
          description={toPlainText(product._rawBody)}
          socialImage={
            product.mainImage?.asset
              ? { asset: product.mainImage?.asset }
              : undefined
          }
        />
      )}

      {errors && <GraphQLErrorList errors={errors} />}

      {product && <Product {...product} />}
    </React.Fragment>
  );
};

export default ProductPageTemplate;
